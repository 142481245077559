<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.folder.group.domainSettings') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.group.domainName') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="title"
          v-model="groupName"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.folder.group.domainGroup') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="group-domain"
          v-model="domain"
          @update="setGroupDomain"
          :disabled="!isSysAdmin"
        ></b-form-input>
        <b-button
          v-if="showSaveButton"
          class="mt-3"
          @click="saveDomain"
        >
          {{ $t('settings.folder.group.button') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UpdateGroupDomain from '@/graphQlQueries/mutations/updateGroupDomain';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import UpdatePirschDomain from '@/graphQlQueries/mutations/updatePirschDomain';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';

export default {
  name: 'FolderSettingsDomain',
  mixins: [SendPublishedDataToServer],
  props: ['groupName', 'groupDomain', 'groupId', 'groupSlug', 'isSysAdmin', 'pirschCode'],
  data: () => ({
    domain: null,
    oldDomain: null,
    showSaveButton: false,
  }),
  created() {
    this.domain = this.groupDomain;
    this.oldDomain = this.groupDomain;
  },
  methods: {
    setGroupDomain(value) {
      this.showSaveButton = true;
      this.domain = value;
    },
    async saveDomain() {
      let publishedDataWasSuccessfulSend = null;
      try {
        publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'updateRootFolder',
          data: {
            groupSlug: this.groupSlug,
            groupDomain: this.domain,
            oldGroupDomain: this.oldDomain,
          },
        });
      } catch (ex) {
        console.log(ex);
      }
      if (publishedDataWasSuccessfulSend) {
        try {
          if (this.pirschCode && this.domain !== 'production') {
            // remove https:// and http:// from the domain
            const pirschDomain = this.domain.replace(/(^\w+:|^)\/\//, '');
            console.log('Update Pirsch domain', pirschDomain);
            await this.$apollo.mutate({
              mutation: UpdatePirschDomain,
              variables: {
                obj: {
                  domain: pirschDomain,
                  pirsch_code: this.pirschCode,
                },
              },
            });
          }
        } catch (ex) {
          console.log('Pirsch could not be updated!', ex);
        }
        try {
          await this.$apollo.mutate({
            mutation: UpdateGroupDomain,
            variables: {
              groupId: this.groupId,
              domain: this.domain,
            },
          });
          const domain = this.oldDomain;
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path: '',
            },
          });
          // after successfully change the group domain set the new domain to the old one
          this.oldDomain = this.domain;
          this.showSaveButton = false;
        } catch (ex) {
          console.log(ex);
        }
      }
    },
  },
};
</script>
